<script>
import Layout from "../../../layouts/main.vue";
import axios from "axios";
import Swal from "sweetalert2";
// import Multiselect from 'vue-multiselect'


export default {
    components: {
        Layout,
        // Multiselect
    },
    data() {
        return {
            prescription: [],
            doctor: [],
            user: [],
            date: [],
            note: [],
            diseases: [],
            medicines: []
        };
    },
    methods: {
        async getPrescription() {
            const response = await axios.get(
                "https://api.doctosoft.com/index.php/api/medical/history/" + this.$route.params.id
            );
            this.prescription = response.data;
            // console.log(this.prescription.note)

            await axios.get('https://api.doctosoft.com/index.php/api/doctor').then((response) => {

                this.doctor = response.data
            })

            await axios.get('https://api.doctosoft.com/index.php/api/user').then((response) => {

                this.user = response.data
            })

            await axios.get('https://api.doctosoft.com/index.php/api/disease/').then((response) => {

                this.diseases = response.data
            })

            await axios.get('https://api.doctosoft.com/index.php/api/medicine/').then((response) => {

                this.medicines = response.data
            })

        },
        
        successmsg(name) {
            Swal.fire("Good job!", "Updated the prescription details of " + name, "success");
        },
        
        async updatePrescription() {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            var urlencoded = new URLSearchParams();
            urlencoded.append("doctor", this.prescription.doctorId);
            urlencoded.append("user", this.prescription.userId);
            urlencoded.append("date", this.prescription.date);
            urlencoded.append("note", this.prescription.note);
            urlencoded.append("diseases", JSON.stringify(this.prescription.diseases));
            urlencoded.append("medicines", JSON.stringify(this.prescription.medicines));

            await axios.put(
                "https://api.doctosoft.com/index.php/api/medical/history" + "/" + this.$route.params.id,
                urlencoded
            );
            this.successmsg(this.prescription.user)
            this.$router.push("/admin/prescription");
        },
    },
    created() {
        let currentDate = new Date();
        let nextDate = new Date();
        nextDate.setDate(currentDate.getDate());
        let startYear = currentDate.getFullYear();
        let startMonth = (currentDate.getMonth() + 1).toString().padStart(2, "0");
        let startDay = currentDate.getDate().toString().padStart(2, "0");
        let endYear = nextDate.getFullYear();
        let endMonth = (nextDate.getMonth() + 1).toString().padStart(2, "0");
        let endDay = nextDate.getDate().toString().padStart(2, "0");
        this.startDate = `${startYear}-${startMonth}-${startDay}`;
        this.endDate = `${endYear}-${endMonth}-${endDay}`;
        this.getPrescription();
    },
};
</script>
<template>
    <Layout>
        <div class="back-btn">
            <div class="arrow">
                <button class="btn btn-light" @click="$router.push('/admin/prescription')"><i
                        class="mdi mdi-chevron-left"></i></button>
            </div>
            <div class="action-btn">
                <b-button class="btn-soft-info waves-effect waves-light"
                    @click="$router.push('/admin/prescription')">Cancel</b-button>
                <button class="btn  btn-md btn btn-primary" type="submit" @click="updatePrescription">Save</button>
            </div>
        </div>
        <b-row class="card-main">
            <b-col sm="6" xl="10">
                <b-card no-body>
                    <form class="needs-validation" @submit.prevent="updatePrescription">

                        <div class="mb-3">
                            <label for="doctor" class="form-label">Doctor</label>
                            <select class="form-select" aria-label="size 3 select example" v-model="prescription.doctorId">
                                <option v-for="doc in doctor" :key="doc.id" :value="doc.id">
                                    {{ doc.name }}
                                </option>
                            </select>
                        </div>

                        <div class="mb-3">
                            <label for="user" class="form-label">User</label>
                            <select class="form-select" aria-label="size 3 select example" v-model="prescription.userId">
                                <option v-for="users in user" :key="users.id" :value="users.id">
                                    {{ users.name }}
                                </option>
                            </select>
                        </div>

                        <div class="mb-3">
                            <label for="date" class="form-label">Date <span class="text-danger">*</span></label>
                            <input type="date" :min="startDate" :max="endDate" v-model="prescription.date"
                                class="form-control" id="exampleInputdate" />
                        </div>

                        <div class="mb-3">
                            <label for="note" class="form-label">Note <span class="text-danger">*</span></label>
                            <input type="text" class="form-control" v-model="prescription.note"
                                placeholder="Enter note" />
                        </div>

                        <div class="mb-3">
                            <label for="diseases" class="form-label">Diseases <span class="text-danger">*</span></label>
                            <select class="form-select" multiple aria-label="multiple select example" v-model="prescription.diseases">
                                <option v-for="dis in diseases" :key="dis.id" :value="dis.id">
                                    {{ dis.name }}</option>
                            </select>
                        </div>

                        <!-- <div class="mb-3">
                            <label for="diseases" class="form-label">Diseases <span class="text-danger">*</span></label>
                            <multiselect v-model="prescription.diseases" :options="this.diseases" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Pick some" label="name" track-by="name" :preselect-first="true">
                            </multiselect>
                        </div> -->

                        <div class="mb-3">
                            <label for="medicine" class="form-label">Medicines <span class="text-danger">*</span></label>
                            <select class="form-select" multiple aria-label="multiple select example" v-model="prescription.medicines">
                                <option v-for="med in medicines" :key="med.id" :value="med.id">
                                    {{ med.name }}</option>
                            </select>
                        </div>

                    </form>
                </b-card>
            </b-col>
        </b-row>
</Layout></template>
